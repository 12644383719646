import { render, staticRenderFns } from "./MadeTaskKycInformer.vue?vue&type=template&id=cf616a4c&scoped=true"
import script from "./MadeTaskKycInformer.vue?vue&type=script&setup=true&lang=js"
export * from "./MadeTaskKycInformer.vue?vue&type=script&setup=true&lang=js"
import style0 from "./MadeTaskKycInformer.vue?vue&type=style&index=0&id=cf616a4c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf616a4c",
  null
  
)

export default component.exports