<style lang="scss" scoped>
  .info-box {
    display: flex;
    padding: 20px;
    background-color: rgba(black, .1);
    border-left: 3px solid rgba(white, .3);
    border-radius: 3px;
    color: rgba(white, .75);
    text-align: left;

    &.box-error {
      background-color: rgba($error, .3);
      border-color: $error;
    }

    &.box-warning {
      background-color: rgba(#FFE500, 0.10);
      border-color: #FFE500;
    }

    .box-icon {
      width: 20px;
      font-size: 20px;
      line-height: 1;
    }

    .box-content {
      font-size: 15px;
    }

    .box-icon + .box-content {
      margin-left: 10px;
    }

    &.small {
      padding: 15px 15px 15px 20px;

      .box-content {
        font-size: 12px;
      }
    }
  }
</style>

<template>
  <div class="info-box" :class="classes">
    <div v-if="showIcon" class="box-icon">
      <icon :name="iconName"/>
    </div>
    <div class="box-content">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    variant: {
      type: String,
      default: 'info',
    },
    icon: {
      type: String,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      icons: {
        info: 'info-circle',
        error: 'info-circle',
      },
    };
  },
  computed: {
    classes() {
      return {
        [`box-${this.variant}`]: !!this.variant,
      };
    },

    iconName() {
      return this.icon ?? this.icons[this.variant];
    },
  },
};
</script>
