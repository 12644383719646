import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import usePromise from '@composables/use-promise'

export const useMadeTaskKycStore = defineStore('madeTaskKyc', () => {
  const verificationLevels = ref([])
  const verificationStatus = ref(null)
  const verificationWarning = ref(false)
  const verificationAvailable = ref(false)

  const fetchKycWarningPromise = usePromise(() => api2.get('/userkycmadetaskverification/warning'))

  const fetchKycWarning = async () => {
    await fetchKycWarningPromise.exec()

    const { data } = fetchKycWarningPromise.data.value

    verificationWarning.value = data.is_warning
  }

  const fetchKycVerificationPromise = usePromise(() => api2.get('/userkycmadetaskverification/info'))

  const fetchKycVerification = async () => {
    try {
      await fetchKycVerificationPromise.exec()
      const { data } = fetchKycVerificationPromise.data.value

      verificationStatus.value = data.status
      verificationLevels.value = data.verifications
      verificationAvailable.value = true
    } catch {
      verificationAvailable.value = false
    }
  }

  return {
    fetchKycVerification,
    fetchKycWarning,
    verificationLoading: fetchKycVerificationPromise.pending,
    verificationLevels,
    verificationStatus,
    verificationWarning,
    verificationAvailable,
  }
})
