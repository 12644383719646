<style scoped lang="scss">
  .dashboard-header {
    height: $dashboard-header-height;
    background-color: #262626;
    //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    //filter: drop-shadow(0 6px 10px rgba(0, 0, 0, 0.1));

    .header-content {
      @include margin-x(auto);
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      .mobile-menu {
        @include media(min, lg) {
          display: none;
        }

        .mobile-menu-button {
          height: 40px;
          width: 40px;
          font-size: 20px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $additional-2;
          border-radius: 8px;
          cursor: pointer;
          user-select: none;

          &:hover {
            background-color: $additional-1;
          }
        }

        .mobile-menu-wrapper {
          position: fixed;
          z-index: 20;
          top: $dashboard-header-height;
          left: 0;
          bottom: 0;
          background-color: $additional-3;
          padding: 10px;
          width: 280px;
          overflow-y: auto;
          margin-top: 1px;
        }
      }

      .logo-wrapper {
        width: $menu-width;
        display: flex;
        align-items: center;

        @include media(max, lg) {
          width: auto;
          margin-left: $dashboard-content-gap;
        }

        @include media(max, sm) {
          display: none;
        }

        .logo-title {
          margin-left: 12px;
          font-weight: 700;
          text-transform: uppercase;

          @include media(max, md) {
            display: none;
          }

          &.da {
            background: linear-gradient(265deg, #f59c07, #f57507);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.astrum {
            color: white;
          }
        }

        .logo-icon {
          font-size: 40px;
        }
      }

      .header-panel {
        margin-left: $dashboard-content-gap;
        display: flex;
        align-items: center;
        flex-grow: 1;

        @include media(max, sm) {
          margin-left: 12px;
        }

        .boosty-link {
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          font-size: 16px;
          color: $gray;
          @include padding-x(15px);
          background-color: $additional-2;

          &:hover {
            background-color: $additional-1;
          }

          .icon {
            font-size: 20px;
          }

          .button-text {
            margin-left: 10px;
          }

          @include media(max, sm) {
            display: none;
          }
        }

        .header-controls {
          margin-left: auto;
          display: flex;
          align-items: center;

          .header-section + .header-section {
            margin-left: 14px;
          }

          .header-balance-section {
            @include media(max, sm) {
              display: none;
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div class="dashboard-header">
    <div class="header-content container">
      <div v-if="getUserIsStreamer" class="mobile-menu" v-click-outside="hideMenu">
        <div class="mobile-menu-button" @click="toggleMenu">
          <icon v-if="menuOpened" name="cross"/>
          <icon v-else name="bars"/>
        </div>
        <transition name="fade-up">
          <div v-if="menuOpened" class="mobile-menu-wrapper">
            <dashboard-menu/>
          </div>
        </transition>
      </div>

      <template v-if="isAstrumApp">
        <router-link :to="{name: 'dashboard-offers'}" class="logo-wrapper" @click.native="onLogoClick">
          <icon class="logo-icon" name="tp-logo"/>
          <div class="logo-title astrum">TalentPulse</div>
        </router-link>
      </template>
      <template v-else>
        <router-link :to="{name: 'dashboard'}" class="logo-wrapper" @click.native="onLogoClick">
          <icon class="logo-icon" name="da-logo" gradient/>
          <div class="logo-title da">DonationAlerts</div>
        </router-link>
      </template>

      <div class="header-panel">
        <template v-if="!isAstrumApp">
          <a href="https://boosty.to/app/da" target="_blank" class="boosty-link">
            <icon name="social-boosty-colored"/>
            <span class="button-text">Boosty</span>
          </a>
        </template>

        <div class="header-controls">
          <template v-if="getUserIsStreamer">
            <header-donations-section class="header-section"/>
            <header-adv-section class="header-section"/>
            <template v-if="!isAstrumApp">
              <header-notifications-section class="header-section"/>
            </template>
          </template>
          <template v-else-if="getUserIsViewer && !getUserIsStreamer">
            <div class="header-section">
              <btn href="/auth/register" variant="primary-action">
                {{ $t('dashboard.user.becomeStreamer') }}
              </btn>
            </div>
          </template>
          <header-profile-section class="header-section"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HeaderAdvSection from './HeaderAdvSection.vue'
import HeaderDonationsSection from './HeaderDonationsSection.vue'
import HeaderNotificationsSection from './HeaderNotificationsSection'
import HeaderProfileSection from './HeaderProfileSection'
import DashboardMenu from '@components/Dashboard/NewDashboard/DashboardMenu'

export default {
  name: 'DashboardHeader',
  components: {
    DashboardMenu,
    HeaderProfileSection,
    HeaderNotificationsSection,
    HeaderAdvSection,
    HeaderDonationsSection
  },
  data() {
    return {
      menuOpened: false,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters('profile', ['getUserIsStreamer', 'getUserIsViewer']),
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened
    },

    hideMenu() {
      this.menuOpened = false
    },

    onLogoClick() {
      this.$ga.event({
        eventCategory: 'header',
        eventAction: 'header_logo_click',
      })

      this.$tmr.goal({ goal: 'header_logo_click' })
    },
  },
  watch: {
    $route: 'hideMenu',
  },
}
</script>
