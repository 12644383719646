<style scoped lang="scss">
  .made-task-kyc-informer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    @include padding-y(14px);
    @include padding-x(15px);
    background-color: #262626;
    border-top: 1px solid #1F1F1F;

    @include media(max, sm) {
      align-items: flex-start;
      display: grid;
      grid-template:
            "a b" 1fr
            "e c" auto / 40px 1fr;
    }

    .informer-icon {
      flex-shrink: 0;
      font-size: 40px;
      grid-area: a;
    }

    .informer-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      grid-area: b;

      .highlight {
        color: #D63A3A;
      }

      .informer-link {
        white-space: nowrap;
      }
    }

    .informer-control {
      grid-area: c;
    }
  }
</style>

<template>
  <div v-if="displayInformer" class="made-task-kyc-informer">
    <icon name="verification-error" class="informer-icon"/>
    <div class="informer-text">
      <b>Ваши выплаты могут быть ограничены.</b>
      Мы заметили, что ваш объём выплат приближается к лимиту. Скоро вам может потребоваться пройти верификацию.
    </div>
    <div class="informer-control">
      <btn
        :to="{name: 'dashboard-payouts-kyc'}"
        variant="primary-action">
        Пройти верификацию
      </btn>
    </div>
  </div>

</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useMadeTaskKycStore } from '@store/madeTaskKyc'


const madeTaskKycStore = useMadeTaskKycStore()
const { verificationWarning } = storeToRefs(madeTaskKycStore)

const displayInformer = computed(() => {
  return verificationWarning.value
})
</script>

