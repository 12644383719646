<template>
  <info-box class="adv-error">
    <template>
        <tag-replacer class="tag-replacer" :text="text">
          <template #homelink="{ content }">
            <a href="/" target='_blank'>{{ content }}</a>
          </template>
          <template #nextline>
            <br>
          </template>
          <template #settingslink="{ content }">
            <router-link to="/dashboard/offers-v2/settings">{{ content }}</router-link>
          </template>
          <template #payoutlink="{ content }">
            <router-link to="/dashboard/payouts">{{ content }}</router-link>
          </template>
        </tag-replacer>
      </template>
  </info-box>
</template>

<script>
  import { getOfferErrorText } from '@utils/offers'

  export default {
    name: 'OffersError',

    props: {
      code: {
        type: [ String, Number ],
        required: true
      }
    },

    computed: {
      text: (vm) => vm.$t(getOfferErrorText(vm.code))
    },
  }
</script>

<style scoped>
.adv-error {
  margin-bottom: 20px;
}
</style>
